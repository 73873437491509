<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) A sample of
        <chemical-latex :content="atomName1" />
        gas has a mass of
        <number-value :value="mass" unit="\text{g.}" />
        How many <b>moles</b> of the element do you have?
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) If you had an equal number of moles of
        <chemical-latex :content="atomName2" />
        gas, how much would that sample weigh?
      </p>

      <calculation-input
        v-model="inputs.mass2"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question378',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mol: null,
        mass2: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    chemicalElement1() {
      return this.taskState.getValueBySymbol('element1').content;
    },
    atomName1() {
      return this.chemicalElement1.symbol;
    },
    chemicalElement2() {
      return this.taskState.getValueBySymbol('element2').content;
    },
    atomName2() {
      return this.chemicalElement2.symbol;
    },
  },
};
</script>
